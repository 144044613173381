<template>
  <WeCard class="sticky-top mb-3">
    <div class="row align-items-center">
      <div class="col">
        <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
      </div>
      <div class="col-auto">
        <div class="row align-items-center">
          <div class="col-auto p-0">
            <WeLanguageSwitcher v-model="slider.lang" />
          </div>
          <div class="col pl-0">
            <!-- Submit -->
            <WeSubmitButton
              parent-class="text-center"
              v-bind:submit-status="submit"
              v-bind:update="$route.params.id"
              v-bind:permission="checkPermission"
              v-on:submit="submitForm"
            />
            <!-- ./Submit -->
          </div>
        </div>
      </div>
    </div>
  </WeCard>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: () => [],
    },
  },
  methods: {
    submitForm() {
      this.$emit("save-form");
    },
    checkError(type) {
      return this.errors.includes(type);
    },
  },
  computed: {
    ...mapState(["shared", "slider"]),
    checkPermission() {
      return permission.check("shared", "u");
    },
     getTitle() {
      return this.$route.params.id ? this.$t('editSlider') : this.$t('addSlider');
    },
  },
};
</script>
